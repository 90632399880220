import SenderIDTable from './senderidTable.vue';
import appConfig from '@/app.config';
export default {
  page: {
    title: 'Pending sender IDs',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  name: 'pending-sender_ids',
  components: {
    SenderIDTable
  },
  data() {
    return {
      parent: {
        name: 'pending',
        storeDataName: 'pendingSenderIDs',
        from: 'pending',
        status: 'Pending'
      }
    };
  }
};